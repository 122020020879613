import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './contexts/AuthContext'; // Import the useAuth hook

function Navbar() {
  const { isLoggedIn, logout } = useAuth(); // Use the isLoggedIn state and logout function

  return (
    <nav className="flex justify-between items-center bg-gray-800 p-4">
      <div className="text-4xl text-white font-bold">botzone.ai</div>
      <div className="flex items-center">
        {/* Search Icon */}
        <div className="text-white mx-2">
          <FontAwesomeIcon icon={faSearch} />
        </div>
        {/* User Icon - Render based on login status */}
        {isLoggedIn ? (
          <div className="text-white mx-2 cursor-pointer" onClick={logout}>
            <FontAwesomeIcon icon={faUser} />
            {/* Optionally, add text like "Logout" */}
          </div>
        ) : (
          <div className="text-white mx-2">
            <FontAwesomeIcon icon={faUser} />
            {/* Optionally, add text like "Login" */}
          </div>
        )}
        {/* Hamburger Menu */}
        <div className="text-white mx-2">
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
