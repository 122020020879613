// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext'; // Import the AuthProvider
import Navbar from './Navbar'; // Your Navbar component
import MainMenu from './MainMenu';
import HomePage from './HomePage'; // Your HomePage component
import CreateBot from './CreateBot'; // Your CreateBot component

function App() {
  return (
    <AuthProvider> {/* Wrap the entire app inside AuthProvider */}
      <Router>
        <div className="flex flex-col h-screen">
          <Navbar />
          <div className="flex flex-1 overflow-hidden bg-gray-800">
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
